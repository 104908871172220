import { Text } from 'components';
import AppleStoreButton from 'pages/start/success/components/AppleStoreButton';
import GooglePlayButton from 'pages/start/success/components/GooglePlayButton';
import React, { FC } from 'react';
import styled from 'styled-components';
import { mobile, smMobile, tablet } from 'styles/breakpoints';
import Success from 'assets/icons/success.svg';

interface RegisterCompletedProps {}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem auto 3rem;
  max-width: 28.75rem;
  width: 100%;
  @media ${tablet} {
    margin: 2.25rem auto 3rem;
    padding: 0 1rem;
  }
`;

const Title = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  padding-bottom: 0.75rem;
`;

const Label = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4375rem;
  padding-bottom: 5.5rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 0.6875rem;
`;

const SvgContainer = styled.div`
  width: 5rem;
  height: 5rem;
  margin-bottom: 1.5rem;
  svg {
    width: 5rem;
    height: 5rem;
  }
`;

const ButtonText = styled.p`
  color: #000;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 1rem;
`;

const AppleStoreButtonStyled = styled(AppleStoreButton)``;
const GooglePlayButtonStyled = styled(GooglePlayButton)``;

const RegisterCompleted: FC<RegisterCompletedProps> = () => (
  <Container>
    <SvgContainer>
      <Success />
    </SvgContainer>
    <Title>You have successfully registered your account!</Title>
    <Label>
      Use your email & recently created password to log into the Welcome Baby
      app.
    </Label>
    <ButtonText>You can download the app using the links below:</ButtonText>
    <ButtonContainer>
      <AppleStoreButtonStyled />
      <GooglePlayButtonStyled />
    </ButtonContainer>
  </Container>
);

export default RegisterCompleted;
